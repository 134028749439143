<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default"
  [opened]="!isScreenSmall" *ngIf="navigation">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20">
      <img class="w-8" src="assets/images/logo/logo.png" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <div class="flex items-center pr-2 space-x-2">
      <!-- Navigation toggle button -->
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
      <!-- Navigation appearance toggle button -->
      <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
      </button>
      <header-text></header-text>
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <search [appearance]="'bar'"></search>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <shortcuts></shortcuts> -->
      <!-- <messages></messages> -->
      <notifications></notifications>
      <!-- <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
      </button> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto pt-4 md:pt-0">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary"
      >© / 2021 todos los derechos reservados a Aberturas de Aluminio S.A.</span
    >
  </div> -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->