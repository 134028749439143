import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PushNotificationService } from '@shared/services/firebase/push-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Constructor
   */
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private notification: PushNotificationService) {
    this.iconRegistry.addSvgIcon('whatsapp', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp.svg'));
  }
  
  ngOnInit():void{
    this.notification.requestPermission();
    this.notification.receiveMessage();
  }
}
