export enum IRoutesPermissions {
  'trazabilidadPedidos' = 95,
  'devoluciones' = 96,
  'rrssCalendario' = 98,
  'usuarios' = 100,
  'calendario' = 101,
  'seguimientoObras' = 102,
  'seguimientoProfesionales' = 103,
  'cotizador' = 104,
  'book' = 105,
  'taller' = 106,
  'remitosAasa' = 107,
  'procesoPlantaPintura' = 107,
  'noticiasWeb' = 108,
  'competencia' = 109,
  'mailing' = 110,
  'estadisticas' = 111,
  'configuracion' = 112,
  'panelControl' = 113,
  'mediciones' = 118,
  'cartelesObra' = 119,
  'cargaBook' = 120,
  'listadoPersonal' = 121,
  'postulantes' = 122,
  'misClientes' = 123,
  'pedidos' = 125,
  'articulos' = 125,
  'demoraOc' = 125,
  'inventario' = 125,
  'remitos_internos' = 125,
  'stock_panol' = 125,
  'perfilesPendientes' = 126,
  'clientes' = 127,
  'oportunidades' = 127,
  'mapa' = 128,
  'busquedas' = 129,
  'listadoWhatsapp' = 131,
  'vehiculos' = 132,
  'configuracionPrecios' = 133,
  'sueldosDigitales' = 134,
  'calculadora' = 138
}

export const IModulesPermissions = {
  ventas: [
    IRoutesPermissions.calendario,
    IRoutesPermissions.book,
    IRoutesPermissions.seguimientoProfesionales,
    IRoutesPermissions.seguimientoObras,
    IRoutesPermissions.oportunidades,
    IRoutesPermissions.mediciones,
    IRoutesPermissions.cartelesObra,
    IRoutesPermissions.misClientes,
    IRoutesPermissions.clientes,
  ],
  taller: [
    IRoutesPermissions.procesoPlantaPintura,
    IRoutesPermissions.perfilesPendientes,
    IRoutesPermissions.trazabilidadPedidos,
    IRoutesPermissions.taller,
    IRoutesPermissions.devoluciones,
    IRoutesPermissions.remitosAasa,
  ],
  materiales: [
    IRoutesPermissions.articulos,
    IRoutesPermissions.pedidos,
    IRoutesPermissions.demoraOc],
  mantenimiento: [IRoutesPermissions.vehiculos],
  comunicacion: [IRoutesPermissions.rrssCalendario, IRoutesPermissions.cargaBook, IRoutesPermissions.noticiasWeb],
  rrhh: [IRoutesPermissions.busquedas, IRoutesPermissions.sueldosDigitales],
  general: [IRoutesPermissions.usuarios, IRoutesPermissions.configuracion, IRoutesPermissions.panelControl],
  gerencia: [IRoutesPermissions.estadisticas],
  configuracionPrecios: [IRoutesPermissions.configuracionPrecios],
  calculadora: [IRoutesPermissions.calculadora],
};