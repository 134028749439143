import {
  BlockScrollStrategy,
  Overlay,
  OverlayModule,
} from '@angular/cdk/overlay';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { CenteredLayoutComponent } from './layout/layouts/horizontal/centered/centered.component';
import { ClassicLayoutComponent } from './layout/layouts/vertical/classic/classic.component';
import { ClassyLayoutComponent } from './layout/layouts/vertical/classy/classy.component';
import { CommonModule } from '@angular/common';
import { CompactLayoutComponent } from './layout/layouts/vertical/compact/compact.component';
import { DenseLayoutComponent } from './layout/layouts/vertical/dense/dense.component';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';
import { EnterpriseLayoutComponent } from './layout/layouts/horizontal/enterprise/enterprise.component';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { FuturisticLayoutComponent } from './layout/layouts/vertical/futuristic/futuristic.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialLayoutComponent } from './layout/layouts/horizontal/material/material.component';
import { MessagesComponent } from './messages/messages.component';
import { ModernLayoutComponent } from './layout/layouts/horizontal/modern/modern.component';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications/notifications.component';
import { PortalModule } from '@angular/cdk/portal';
import { QuickChatComponent } from './quick-chat/quick-chat.component';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { SettingsComponent } from './settings/settings.component';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StoreModule } from '@ngrx/store';
import { ThinLayoutComponent } from './layout/layouts/vertical/thin/thin.component';
import { UserComponent } from './user/user.component';
import { reducer } from '@root-store/auth-store/auth.reducer';
import { FuseAlertModule } from '@fuse/components/alert';
import { CarouselComponent } from './carousel/carousel.component';
import { RecargaDirective } from './directives/recarga.directive';
import { HeaderTextComponent } from './header-text/header-text.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SeleccionImagenComponent } from './seleccion-imagen/seleccion-imagen.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { StockPanolService } from '@features/materiales/stock-panol/services/stock-panol.services';

const components = [
  LayoutComponent,
  MessagesComponent,
  NotificationsComponent,
  HeaderTextComponent,
  QuickChatComponent,
  SearchComponent,
  SettingsComponent,
  ShortcutsComponent,
  UserComponent,
  EmptyLayoutComponent,
  CenteredLayoutComponent,
  EnterpriseLayoutComponent,
  MaterialLayoutComponent,
  ModernLayoutComponent,
  ClassicLayoutComponent,
  ClassyLayoutComponent,
  CompactLayoutComponent,
  DenseLayoutComponent,
  FuturisticLayoutComponent,
  ThinLayoutComponent,
  SpinnerComponent,
  CarouselComponent,
  RecargaDirective,
  SeleccionImagenComponent
  // CarouselItemElement
];

@NgModule({
  declarations: [...components],
  imports: [
    MatGridListModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    FuseDrawerModule,
    FuseScrollbarModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDividerModule,
    FuseLoadingBarModule,
    FuseFullscreenModule,
    FuseNavigationModule,
    MatAutocompleteModule,
    RouterModule,
    ReactiveFormsModule,
    StoreModule.forFeature('auth', reducer),
    FuseAlertModule
  ],
  exports: [...components],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => (): BlockScrollStrategy =>
        overlay.scrollStrategies.block(),
      deps: [Overlay],
    },StockPanolService
  ],
})
export class SharedComponentModule { }
