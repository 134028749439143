import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedComponentModule } from "./components/shared-component.module";
import { MatIconModule } from "@angular/material/icon";
import { SanitizerPipe } from "./pipes/sanitizer";
import { CarouselItemDirective } from "./directives/carousel";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    MatIconModule,
  ],
  exports: [
    SharedComponentModule,
    MatIconModule,
    SanitizerPipe,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    MatIconModule,
    CarouselItemDirective,
    BreadcrumbsComponent
  ],
  declarations: [SanitizerPipe, CarouselItemDirective, BreadcrumbsComponent],
})
export class SharedModule {}
