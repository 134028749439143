<div class=" inset-0 sm:static max-h-screen max-w-screen flex flex-col sm:rounded-2xl  overflow-y "
    [@expandCollapse]="'expanded'">
    <!-- ENCABEZADO -->
    <div class="w-full p-8 flex justify-between items-center">
        <div class="w-5/12">
            <span class="text-gray-700 font-bold text-md lg:text-2xl dark:text-white">{{ titulo }}</span>
        </div>
        <div *ngIf="mostrarBuscador" class="w-5/12">
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64">
                <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:search"> </mat-icon>
                <input matInput [formControl]="buscador" placeholder="Buscar" autocomplete="off" #buscar
                    (keyup)="_filter(buscar.value)" />
            </mat-form-field>
        </div>
        <button mat-icon-button (click)='onClose()' class="ml-auto">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="!esArregloDeArreglos" class="p-8 flex flex-col">
        <mat-grid-list [cols]="columnas" [rowHeight]="getProporcion()" gutterSize="2vh">
            <mat-grid-tile *ngFor='let objeto of arreglo'
                class="bg-white rounded-[10px] border border-gray-500 justify-between hover:bg-slate-400/10 hover:border-slate-700/10 cursor-pointer hover:scale-105 transition duration-150 ease-in-ou">
                <div class="flex flex-col cursor-pointer p-5" (click)='onSeleccionar(objeto)'>
                    <img [src]="objeto.imagen" alt="Imagen" class="w-full h-full ">
                    <p *ngIf="verTitulo" class="text-center text-black text-base font-sans leading-normal">
                        {{objeto.titulo}}</p>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div *ngIf="esArregloDeArreglos">
        <div *ngFor='let objeto of arreglo' class="p-4 flex flex-col">
            <div *ngIf="objeto.titulo"
                class="w-full background-color-titulo rounded-[10px] my-2 justify-center items-center flex flex-row">
                <span class="text-white font-bold text-md lg:text-2xl dark:text-white">{{objeto.titulo}}</span>
            </div>
            <mat-grid-list [cols]="columnas" [rowHeight]="getProporcion()" gutterSize="2vh">
                <mat-grid-tile *ngFor='let obj of objeto.arreglo'
                    class="bg-white rounded-[10px] border border-gray-500 justify-between hover:bg-slate-400/10 hover:border-slate-700/10 cursor-pointer hover:scale-105 transition duration-150 ease-in-ou">
                    <div class="flex flex-col cursor-pointer p-5" (click)='onSeleccionar(obj)'>
                        <img [src]="obj.imagen" alt="Imagen" class="w-full h-full">
                        <p *ngIf="verTitulo" class="text-center text-black text-base font-sans leading-normal">
                            {{obj.titulo}}</p>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</div>