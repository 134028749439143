import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-seleccion-imagen',
  templateUrl: './seleccion-imagen.component.html',
  styleUrls: ['./seleccion-imagen.component.scss'],
  animations: fuseAnimations
})
export class SeleccionImagenComponent implements OnInit {
  protected arreglo = [];
  protected verTitulo: boolean = false;
  protected vertical: boolean = false;
  protected titulo: string = "";
  protected esArregloDeArreglos: boolean = false;
  protected buscador = new FormControl;
  protected arregloAux = [];
  protected columnas: number = 5;
  protected mostrarBuscador = false;
  /**Módulo de selección por imágenes
   * recibe por parámetro data={titulo:string, arreglo:[], verTitulo:boolean, vertical:boolean,esArregloDeArreglos:boolean, columnas:number }
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SeleccionImagenComponent>,) {
    this.titulo = data.titulo;
    this.arreglo = data.arreglo;
    this.arregloAux = data.arreglo;
    this.verTitulo = data.verTitulo;
    this.vertical = data.vertical;
    this.esArregloDeArreglos = data.esArregloDeArreglos;
    this.columnas = data.columnas;
    this.mostrarBuscador = data.mostrarBuscador ? data.mostrarBuscador : false;
  }

  ngOnInit(): void {
    this.updateCols(window.innerWidth);
    // Suscripción al evento de cambio de tamaño de la ventana
    window.addEventListener('resize', (event) => {
      this.updateCols((event.target as Window).innerWidth);
    });
  }
  _filter(dato: string) {
    if (this.esArregloDeArreglos) {
      this.arreglo = this.arregloAux.map((objeto) => {
        return {
          ...objeto,
          arreglo: objeto.arreglo.filter((obj) =>
            obj.titulo.toLowerCase().includes(dato)
          ),
        };
      });
    } else {
      this.arreglo = this.arregloAux.filter((objeto) =>
        objeto.titulo.toLowerCase().includes(dato)
      );
    }
  }

  onClose(res = '') {
    this.dialogRef.close(res);
  }
  onSeleccionar(target: any) {
    this.onClose(target.codigo);
  }

  getProporcion() {
    return !this.vertical ? "2:1" : "3:4"
  }


  private updateCols(windowWidth: number): void {
    // Define lógica para ajustar el número de columnas según el tamaño de la pantalla
    if (windowWidth >= 1280) {
      this.columnas = 4;
    } else if (windowWidth >= 960) {
      this.columnas = 3;
    } else if (windowWidth >= 600) {
      this.columnas = 2;
    } else {
      this.columnas = 2;
    }
  }
}

