import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, Messaging } from 'firebase/messaging';
import { environment } from '../../../../environments/environment'; // Asegúrate de ajustar la ruta según tu estructura de carpetas
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthLocalService } from '@core/auth/auth-local.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private messaging: Messaging;

  constructor(
    private http: HttpClient,
    private authLocalService: AuthLocalService,
  ) {
    const firebaseConfig = {
      apiKey: "AIzaSyCdxgANZPCbXz0KQV6rYVy1mfBuq6dSPfg",
      authDomain: "aasa-236601.firebaseapp.com",
      projectId: "aasa-236601",
      storageBucket: "aasa-236601.appspot.com",
      messagingSenderId: "855907669144",
      appId: "1:855907669144:web:67dde6147660046c282ced",
      measurementId: "G-7HDK8HNBHK"
    };

    const app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(app);
  }

  // Solicitar permisos para notificaciones
  async requestPermission(): Promise<void> {
    try {
      await Notification.requestPermission();
      if (Notification.permission === 'granted') {
        console.log('Permiso de notificación concedido.');
        const currentToken = await getToken(this.messaging);
        if (currentToken) { //Se podría llegar a hacer una variable en servicio en donde acá se setee el token para obtenerlo desde otro lado en caso de que se necesite.
          console.log('FCM token:', currentToken);
          //Aca se podría guardar el token en el back en caso de ser necesario.
        } else {
          console.log('No se obtuvo el token de registro. Solicita permisos para generarlo.');
        }
      } else {
        console.log('No se obtuvo el permiso para notificaciones.');
      }
    } catch (error) {
      console.error('Error al solicitar permiso de notificación:', error);
    }
  }

  async setTokenDispositivo(){
    let user = this.authLocalService.getCurrentUser();
    let payload = {"idusuario" : user.codigo, "dispositivo" : "PWA","token" : await getToken(this.messaging)};
    return this.http.post<any>(environment.api.common.usuario_dispositivos, payload).subscribe({
      next: (data) => {
        console.info("token de dispositivo registrado");
      }, error: (error) => {
        console.error('Error en el servicio user dispo:', error);
      }
    });
  }


  //Manejar mensajes entrantes cuando se esta usando la app. Los mensajes en segundo plano se procesan desde firebase-messaging-sw.js
  receiveMessage(): void {
    onMessage(this.messaging, (payload) => {
      console.log('Mensaje recibido: ', payload);
      // Personaliza la notificación aquí
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: '/firebase-logo.png'
      };
      new Notification(notificationTitle, notificationOptions);
    });
  }
}