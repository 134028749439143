import { AuthGuard } from '@core/auth/guards/auth.guard';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { Route } from '@angular/router';
import { AppResolvers } from './app.resolvers';

export const appRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    resolve: [AppResolvers],
    children: [
      /* {
        path: '',
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
      }, */
      {
        path: 'ventas',
        loadChildren: () => import('./features/ventas/ventas.module').then(m => m.VentasModule),
      },
      {
        path: 'calculadora',
        loadChildren: () => import('./features/calculadora/calculadora.module').then(m => m.CalculadoraModule),
      },
      {
        path: 'clientes',
        loadChildren: () => import('./features/clientes/clientes.module').then(m => m.ClientesModule),
      },
      {
        path: 'taller',
        loadChildren: () => import('./features/taller/taller.module').then(m => m.TallerModule),
      },
      {
        path: '',
        redirectTo: '/materiales/articulos',
        pathMatch: 'full'
      },
      {
        path: 'materiales',
        loadChildren: () => import('./features/materiales/materiales.module').then(m => m.MaterialesModule),
      },
      {
        path: 'mantenimiento',
        loadChildren: () => import('./features/mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule),
      },
      {
        path: 'comunicacion',
        loadChildren: () => import('./features/comunicacion/comunicacion.module').then(m => m.ComunicacionModule),
      },
      {
        path: 'rrhh',
        loadChildren: () => import('./features/rrhh/rrhh.module').then(m => m.RRHHModule),
      },
      {
        path: 'general',
        loadChildren: () => import('./features/general/general.module').then(m => m.GeneralModule),
      },
      {
        path: 'gerencia',
        loadChildren: () => import('./features/gerencia/gerencia.module').then(m => m.GerenciaModule),
      },
      {
        path: 'configuraciones',
        loadChildren: () => import('./features/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule),
      },
      {
        path: 'prospectos',
        loadChildren: () => import('./features/ventas/prospectos/prospectos.module').then(m => m.ProspectosModule),
      },
      {
        path: 'indicadores',
        loadChildren: () => import('./features/indicadores/indicadores.module').then(m => m.IndicadoresModule),
      },
      {
        path: 'common',
        loadChildren: () => import('./features/common-features/common-features.module').then(m => m.CommonFeaturesModule),
      },
      {
        path: 'applications',
        loadChildren: () => import('./features/applications/applications.module').then(m => m.ApplicationsModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('./features/auth/sign-in/sign-in.module').then(m => m.SignInFeatureModule),
      },
      {
        path: 'sign-out',
        loadChildren: () => import('./features/auth/sign-out/sign-out.module').then(m => m.SignOutFeatureModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'common/404',
  },
];
